.saveAndContinueDialog {
  .saveAndContinueDialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .saveAndContinueDialogInner {
    width: 100%;
    height: 100%;
    background-color: #faf5f0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialogContainer {
      margin: 56px auto 16px auto;
      position: relative;
      .plumIcon {
        z-index: -1;
      }
      .checkIcon {
        position: absolute;
        top: -10px;
        left: 0px;
        z-index: 1;
      }
    }
    .title {
      color: #323232;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
    .subtitle {
      color: #636363;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
    .buttonContainer {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .buttonText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }
  }
}
